import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import Img from "gatsby-image";
import SEO from "../components/seo";

export default function BlogPost({ data, pageContext }) {
    const post = data.allWpPost.nodes[0]
    return (
        <Layout>
            <SEO title={post.title} description={post.excerpt} />
            <div className="container py-10 my-5">
                <h1 className="text-center blog-title">{post.title}</h1>
                <p className="text-center">by <span className="font-weight-bold">{post.author.node.name}</span></p>
                <p className="text-center">{post.date}</p>
                <div className="hovereffect py-4">
                    {post.featuredImage &&
                        <Img fluid={post.featuredImage.node.remoteFile.childImageSharp.fluid}
                             alt={post.title}/>
                    }
                </div>
                <div className="blog-text w-75 m-auto" dangerouslySetInnerHTML={{ __html: post.content }} />
                <hr/>
                <div className="clearfix">
                    {pageContext.prev &&
                        <Link to={`/${pageContext.prev.slug}`}>
                            <a className="btn float-left">
                                ← Previous Post
                            </a>
                        </Link>
                    }
                    {pageContext.next &&
                        <Link to={`/${pageContext.next.slug}`}>
                            <a className="btn float-right">
                                Next Post →
                            </a>
                        </Link>
                    }
                </div>
            </div>
        </Layout>
    )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        excerpt
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {  
            remoteFile {
              childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
            }
          }  
        }
        author {
          node {
            name
          }
        }
      }
    }
  }
`